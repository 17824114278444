/* 
  Loads the font Poppins from Google Fonts.
  Example on how to use in CSS:
  font-family: 'Poppins-SemiBold', 'sans-serif';

  Format Poppins-{ Bold/Italic/SemiBold }
*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap');


:root {
  --bg-blue: #1F2E6E;
  --button-red: #FF3A3A;
  --button-blue: #1F2E6E;
  --header-black: #2D2D2F;
  --forum-white: #F5F5F5;
  --mod-yellow: #EFB61E;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins', 'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.deserialised-content p {
  margin-block-end: 0.6em;
  margin-block-start: 0.6em;
}